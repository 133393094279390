<template>
  <div class="account-page main-wrapper-adjust-profile">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <h2 class="my-4">Permanent Address</h2>
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/provider/step-1" class="active-done">1</a>
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">2</a>
                        </li>
                        <li><a href="javascript:void();">3</a></li>
                        <li><a href="javascript:void();">4</a></li>
                        <li><a href="javascript:void();">5</a></li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                        <li><a href="javascript:void();">9</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="form-group">
                        <div class="form-group text-left">
                          <label>Address Line 1</label>
                          <input type="text" name="address1" maxlength="34" class="form-control" v-model="addressLine1"
                            required />
                        </div>
                        <div class="form-group text-left">
                          <label>Address Line 2 (Optional)</label>
                          <input type="text" name="address2" maxlength="34" class="form-control"
                            v-model="addressLine2" />
                        </div>

                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <label>State</label>
                              <select class="form-control select" tabindex="-1" aria-hidden="true" v-model="state"
                                required @change="fetchCities()">
                                <option v-for="(state, i) in statesData" :key="i" :value="state.state_code">
                                  {{ state.state_name }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <label>City</label>
                            <select class="form-control select" tabindex="-1" aria-hidden="true" v-model="city"
                              required>
                              <option v-for="(city, i) in citiesData" :key="i" :value="city.city_name">
                                {{ city.city_name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Zip Code</label>
                          <input type="text" name="zipcode" class="form-control" v-model="zipCode" required />
                        </div>
                      </div>
                      <div class="mt-3">
                        <hr />
                        <div class="row">
                          <div class="col-12">
                            <button type="submit" class="
                                btn btn-primary btn-block btn-lg
                                login-btn
                                step1_submit
                                mb-2
                              ">
                              Continue <i class="fa fa-arrow-right ml-2"></i>
                            </button>
                          </div>
                          <div class="col-12">
                            <router-link to="/provider/step-1">
                              <a href="javascript:void()" class="btn btn-light btn-block">
                                <i class="fa fa-arrow-left mr-2"></i> Back
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span>© 2023. StepAhead Health Inc. </span>
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import common from "../../mixins/common";

export default {
  mixins: [common],
  data() {
    return {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zipCode: "",
      statesData: [],
      citiesData: [],
    };
  },
  async mounted() {
    this.showLoader(true);
    this.statesData = await this.getStateList();

    this.addressLine1 = this.$store.getters.user.address1
      ? String(this.$store.getters.user.address1).substring(0, 35)
      : "";
    this.addressLine2 = this.$store.getters.user.address2
      ? String(this.$store.getters.user.address2).substring(0, 35)
      : "";

    this.zipCode = this.$store.getters.user.zip_code;

    this.state = this.$store.getters.user.state;
    if (this.state !== "") await this.fetchCities(this.state);

    this.city = this.$store.getters.user.city;
    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "showLoader"]),
    async fetchCities() {
      this.showLoader(true);
      this.citiesData = await this.getCityList(this.state);
      this.showLoader(false);
    },

    async saveStep() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          address1: this.addressLine1,
          address2: this.addressLine2,
          city: this.city,
          state: this.state,
          zip_code: this.zipCode,
          current_registration_step: 3,
        });

        this.$router.push("/provider/step-3");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        console.error(e);
      }
    },
  },
};
</script>
<style scoped>
.main-wrapper-adjust-profile {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>