<template>
  <div>
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content">
        <div class="container-fluid">
          <!-- Statistics -->
          <div class="row">
            <!-- <div class="col-12">
              <h4 class="mt-1">Hi, {{ $store.getters.user.first_name }}!</h4>
              <span class="text-italic">Welcome to StepAhead Health</span>
            </div> -->
            <div class="col-12">
              <form @submit.prevent="startSearching" class="search-form mb-2">
                <div class="input-group">
                  <div class="input-group-prepend" v-if="searchPatient">
                    <button
                      type="button"
                      @click="resetSearch"
                      class="btn btn-sm btn-secondary"
                    >
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <input
                    v-model="searchPatient"
                    type="text"
                    placeholder="Search Patient"
                    class="form-control form-control-sm"
                    required
                  />
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-sm btn-info">
                      <i class="fa fa-search"></i> Search
                    </button>
                  </div>
                </div>
                <p
                  v-if="patientSearchResults.length > 0"
                  class="text-small text-muted mt-3"
                >
                  Showing {{ patientSearchResults.length }} results for
                  {{ searchPatient }}:
                </p>
              </form>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-6 pr-1">
                  <div
                    class="stati bg-card-1"
                    @click="$refs.pendingbtn.click()"
                  >
                    <i class="fas fa-file-prescription"></i>
                    <div>
                      <b>{{ pendingPrescriptions.length }}</b>
                      <span class="text-light">New Requests</span>
                    </div>
                  </div>
                </div>
                <div class="col-6 pl-1">
                  <div
                    class="stati bg-card-2"
                    @click="$refs.inprogressbtn.click()"
                  >
                    <i class="fas fa-prescription-bottle"></i>
                    <div>
                      <b>{{ acceptedPrescriptions.length }}</b>
                      <span class="text-light">In Progress</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- List of patient search -->
          <div class="row" v-if="patientSearchResults.length > 0">
            <div class="col-12">
              <ul class="list-group list-holder-2">
                <li
                  v-for="(patient, index) in patientSearchResults"
                  :key="index"
                  class="list-group-item"
                >
                  {{ patient.first_name }} {{ patient.last_name }} :
                  {{ getAge(patient.dob) }} y/o {{ patient.gender }} ({{
                    patient.state
                  }})
                </li>
              </ul>
            </div>
          </div>

          <!-- List of requests -->
          <div v-else class="row">
            <div class="col-12">
              <ul class="nav nav-pills nav-fill nav-custom mt-2">
                <li class="nav-item">
                  <a
                    :class="`nav-link active`"
                    ref="pendingbtn"
                    href="javascript:void();"
                    data-toggle="pill"
                    data-target="#pending"
                    >New Requests
                    <span class="badge badge-info">{{
                      pendingPrescriptions.length
                    }}</span></a
                  >
                </li>
                <li class="nav-item">
                  <a
                    :class="`nav-link`"
                    ref="inprogressbtn"
                    href="javascript:void();"
                    data-toggle="pill"
                    data-target="#inprogress"
                    >In Progress
                    <span class="badge badge-info">{{
                      acceptedPrescriptions.length
                    }}</span></a
                  >
                </li>
              </ul>
              <div class="tab-content">
                <!-- SHOW NEW REQUESTS -->
                <div
                  class="tab-pane show active"
                  id="pending"
                  role="tabpanel"
                  aria-labelledby="pending-tab"
                >
                  <div
                    v-if="pendingPrescriptions.length > 0"
                    class="list-holder"
                  >
                    <div
                      class="card shadow-sm bg-white rounded mb-3"
                      v-for="(prescription, i) in pendingPrescriptions"
                      :key="i"
                    >
                      <router-link
                        v-if="prescription.is_payment_completed"
                        :to="`/provider/request/${prescription.id}`"
                      >
                        <div class="card-body">
                          <div class="row" style="font-size: 12px">
                            <div class="col-6">
                              Request ID:
                              <strong>{{ prescription.id }}</strong>
                              <p class="mb-0 mt-2">
                                Patient:
                                {{
                                  getTrimmedName(
                                    `${prescription.patient.first_name} ${prescription.patient.last_name}`
                                  )
                                }}
                              </p>
                            </div>
                            <div class="col-6">
                              Type:
                              <strong>{{
                                prescription.type === "free"
                                  ? "Free Text"
                                  : "Refill"
                              }}</strong>
                              <p class="mb-0 mt-2">
                                Date:
                                {{
                                  new Date(
                                    prescription.created_at
                                  ).toDateString()
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div v-else class="p-5 text-center">
                    <h5>No New Requests</h5>
                  </div>
                </div>

                <!-- SHOW IN PROGRESS REQUESTS -->
                <div
                  :class="`tab-pane fade`"
                  id="inprogress"
                  role="tabpanel"
                  aria-labelledby="inprogress-tab"
                >
                  <div
                    v-if="acceptedPrescriptions.length > 0"
                    class="list-holder"
                  >
                    <div
                      class="card shadow-sm bg-white rounded mb-3"
                      v-for="(prescription, i) in acceptedPrescriptions"
                      :key="i"
                    >
                      <router-link :to="`/provider/request/${prescription.id}`">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-7">
                              Request ID:
                              <strong>{{ prescription.id }}</strong>
                              <p class="mb-0 mt-2">
                                Date:
                                {{
                                  new Date(
                                    prescription.created_at
                                  ).toDateString()
                                }}
                              </p>
                            </div>
                            <div class="col-5">
                              <p style="font-size: 10px" class="mb-0">
                                <span
                                  v-if="
                                    prescription.status === 'Accepted' &&
                                      prescription.is_payment_completed
                                  "
                                  :class="
                                    getStatusColor(prescription.status) +
                                      ' w-100'
                                  "
                                >
                                  <strong style="font-size: 10px"
                                    >Approval Pending</strong
                                  >
                                </span>
                                <span
                                  v-else
                                  :class="getStatusColor(prescription.status)"
                                >
                                  <strong style="font-size: 10px">{{
                                    pharmacyStatus(prescription.medicines)
                                  }}</strong>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div v-else class="p-5 text-center">
                    <h5>Nothing Yet</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- /Page Content -->
      <layout-footer></layout-footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { BASE_URL, HTTP_API } from "../../constants/config";
import axios from "axios";
import commonMixin from "../../mixins/common";
import Swal from "sweetalert2";

export default {
  mixins: [commonMixin],
  data() {
    return {
      selectedPrescription: null,
      interval: null,
      searchPatient: null,
      patientSearchResults: [],
    };
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  async mounted() {
    this.$store.dispatch("showLoader", true);
    await this.getIdpStatus();

    if (this.interval) clearInterval(this.interval);

    this.$socket.emit("REQUEST", {
      command: "GET:PRESCRIPTIONS",
      userId: this.$store.getters.user.id,
    });

    this.interval = setInterval(() => {
      this.$socket.emit("REQUEST", {
        command: "GET:PRESCRIPTIONS",
        userId: this.$store.getters.user.id,
      });
    }, 5000);

    this.$store.dispatch("showLoader", false);
  },
  methods: {
    ...mapActions(["showLoader", "logOut"]),

    resetSearch() {
      this.searchPatient = null;
      this.patientSearchResults = [];
    },

    async startSearching() {
      try {
        this.showLoader(true);
        const res = await axios.get(
          `${BASE_URL}${HTTP_API.providerSearchPatient}?name=${this.searchPatient}`
        );
        this.patientSearchResults = res.data;

        if (res.data.length === 0) {
          Swal.fire({
            icon: "warning",
            title: "No results found",
            text: "",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 3000,
          });
        }

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          icon: "error",
          title: "No results found",
          text: "",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      // this.patientSearchResults = [{
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }, {
      //   patientId: '12345',
      //   patientName: 'Imtiyaz',
      //   state: 'MH',
      //   age: 29,
      //   gender: 'F'
      // }]
    },

    formatLongString(str) {
      let s = String(str);
      return s.length > 10 ? s.substring(0, 10) + "..." : s;
    },

    async getIdpStatus() {
      try {
        const res = await axios.get(
          `${BASE_URL + HTTP_API.providerGetIdpStatus}${
            this.$store.getters.user.id
          }/${this.$store.getters.user.dsp_user_id}`
        );

        if (!res.data.status) {
          this.$router.push("/provider/agreement");
        }
      } catch (e) {
        this.showLoader(false);
        Swal.fire(
          "Account Suspended",
          "Your account is inactive or it has been suspended by the administrator. Please contact the support team.",
          "error"
        ).then(() => {
          this.logout();
        });
      }
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    async logout() {
      try {
        await this.logOut({
          id: this.$store.getters.user.id,
        });

        this.$router.push("/login");
        // this.$router.go();
      } catch (e) {
        console.error(e);
      }
    },

    getTrimmedName(str) {
      if (String(str).length > 12) return String(str).substring(0, 12) + "...";
      else return str;
    },
    pharmacyStatus(medicines) {
      const isRefillInitiated = medicines.find(
        (m) => m.dsp_status_id === 13 && m.is_refill_initiated
      );
      if (isRefillInitiated) return "Refill Initiated";

      const isRxChangeRequest = medicines.find(
        (m) => m.dsp_status_id === 13 && m.is_rxchange_requested
      );

      if (isRxChangeRequest) return "RxChange Requested";

      const isPharmacyVerified = medicines.find((m) => m.dsp_status_id === 13);
      if (isPharmacyVerified) return "Sent to Pharmacy";

      return "Waiting for Pharmacy";
    },
    getStatusColor(status) {
      switch (status) {
        case "Awaiting":
          return "btn btn-sm bg-warning-light btn-block";

        case "In Progress":
          return "btn btn-sm bg-primary-light btn-block";

        case "Completed":
          return "btn btn-sm bg-success-light btn-block";

        case "Accepted":
          return "btn btn-sm bg-info-light btn-block";

        case "Cancelled":
          return "btn btn-sm bg-danger-light btn-block";

        default:
          return "btn btn-sm bg-primary-light btn-block";
      }
    },
  },
  computed: {
    prescriptions() {
      return this.$store.getters.prescriptions
        ? this.$store.getters.prescriptions
        : [];
    },
    pendingPrescriptions() {
      return this.prescriptions.filter(
        (i) => i.status === "In Progress" && i.is_payment_completed
      );
    },
    acceptedPrescriptions() {
      return this.prescriptions.filter(
        (i) =>
          i.status === "Accepted" ||
          i.status === "PharmacyVerified" ||
          i.status === "Sent"
      );
    },
    completedPrescriptions() {
      return this.prescriptions.filter((i) => i.status === "Completed");
    },
    rejectedPrescriptions() {
      return this.prescriptions.filter((i) => i.status === "Cancelled");
    },
  },
};
</script>

<style scoped>
.stati {
  background: #fff;
  height: 6em;
  padding: 1em;
  margin: 1em 0;
  -webkit-transition: margin 0.5s ease, box-shadow 0.5s ease;
  /* Safari */
  transition: margin 0.5s ease, box-shadow 0.5s ease;
  border-radius: 10px;
  /* -moz-box-shadow: 0px 0.2em 0.4em rgb(0, 0, 0, 0.8);
  -webkit-box-shadow: 0px 0.2em 0.4em rgb(0, 0, 0, 0.8);
  box-shadow: 0px 0.2em 0.4em rgb(0, 0, 0, 0.8); */
}

.stati i {
  font-size: 3.5em;
}

.stati div {
  width: calc(100% - 3.5em);
  display: block;
  float: right;
  text-align: right;
}

.stati div b {
  font-size: 2.2em;
  width: 100%;
  padding-top: 0px;
  margin-top: -0.2em;
  margin-bottom: -0.2em;
  display: block;
}

.stati div span {
  font-size: 14px;
  width: 100%;
  color: rgba(255, 255, 255, 0.8) !important;
  display: block;
}

.stati.left div {
  float: left;
  text-align: left;
}

.stati.bg-card-1 {
  background: rgb(15 215 241);
  color: white;
}

.stati.bg-card-2 {
  background: rgb(18 112 193);
  color: white;
}

.btn-card-left {
  -webkit-border-radius: 10px;
  border: none;
  border-radius: 10px;
  background: #f8f9fa;
  -webkit-box-shadow: 6px 6px 18px #e9eaeb, -6px -6px 18px #ffffff;
  box-shadow: 6px 6px 18px #e9eaeb, -6px -6px 18px #ffffff;
}

.btn-card-right {
  -webkit-border-radius: 10px;
  border: none;
  border-radius: 10px;
  background: #f8f9fa;
  -webkit-box-shadow: 6px 6px 18px #e9eaeb, 6px 6px 18px #ffffff;
  box-shadow: 6px 6px 18px #e9eaeb, 6px 6px 18px #ffffff;
}

.btn-card-left:hover,
.btn-card-right:hover {
  background: #fff;
  animation: press 0.1s 1 linear;
}

.announcements {
  border-radius: 10px;
}

@keyframes press {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.92);
  }

  to {
    transform: scale(1);
  }
}

@keyframes bounce {
  50% {
    transform: rotate(5deg) translate(20px, -50px);
  }

  to {
    transform: scale(0.9) rotate(10deg) translate(50px, -80px);
    opacity: 0;
  }
}

.card-btn {
  cursor: pointer;
}

.card-btn:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.nav-custom {
  background-color: #ebebeb;
  border-radius: 20px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #2284d5;
  border-radius: 20px 20px 20px 20px;
}

.list-holder {
  height: calc(100vh - 250px);
  overflow: auto;
}

.list-holder-2 {
  height: calc(100vh - 380px);
  overflow: auto;
}
</style>
