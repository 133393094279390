<template>
  <div id="app">
    <!-- Generic loader -->
    <loader></loader>

    <!-- Generic alert box -->
    <alert-modal />

    <!-- Header -->
    <layout-header v-if="showHeader"></layout-header>

    <!-- Router view -->
    <router-view :key="$route.fullPath"></router-view>

    <!-- Create Request Modal -->
    <div v-if="showHeader" class="modal fade" id="create-request-modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-lg">
          <div class="modal-body">
            <div class="row">
              <div class="col-12 text-center">
                <h4 class="mb-3">What are you looking for?</h4>
              </div>
              <div class="col-12 patient-dashboard-top">
                <div class="card card-btn btn-card" @click="createNewRequest">
                  <router-link to="/patient/new-prescription">
                    <div class="card-body text-center">
                      <div class="mb-3">
                        <img src="./assets/icons/prescription.png" alt="" width="55" />
                      </div>
                      <small>Create Free Text</small>
                      <h6>Prescription Request</h6>
                    </div>
                  </router-link>
                </div>
              </div>
              <div class="col-12 patient-dashboard-top">
                <div class="card card-btn btn-card" @click="createNewRequest">
                  <router-link to="/patient/new-refill">
                    <div class="card-body text-center">
                      <div class="mb-3">
                        <img src="./assets/icons/refill.png" alt="" width="55" />
                      </div>
                      <small>Already prescribed?</small>
                      <h6>Request For Refill</h6>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <patient-footer v-if="showFooter && !isProvider" @createNewRequest="createNewRequest"></patient-footer>
    <provider-footer v-if="showFooter && isProvider" @createNewRequest="createNewRequest"></provider-footer>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import commonMixin from "./mixins/common";

export default {
  name: "App",
  mixins: [commonMixin],
  data () {
    return {
      coolback: false,
    };
  },
  computed: {
    showHeader () {
      return this.$store.getters.user ? true : false;
    },
    showFooter () {
      return this.$store.getters.user &&
        this.$store.getters.user.is_registration_complete &&
        !this.$route.name.includes("register-provider")
        ? true
        : false;
    },
    isProvider () {
      return this.$store.getters.user.role === "provider";
    },
  },
  async mounted () {
    try {
      setTimeout(() => {
        // REACT NATIVE HANDLERS
        if (window.isNativeApp) {
          this.$store.dispatch("showLoader", true);

          // Send connection message
          ReactNativeWebView.postMessage(
            JSON.stringify({ command: "CONNECTION", data: "CONNECTED" })
          );

          // Response Handlers
          window.postMessage = async (message) => {
            switch (message.command) {
              case "GO_BACK":
                if (
                  !window.history.state.back ||
                  window.history.state.back === "/" ||
                  String(window.history.state.back).includes("login") ||
                  String(this.$route.path).includes("login") ||
                  String(this.$route.path).includes("patient-dashboard")
                ) {
                  ReactNativeWebView.postMessage(
                    JSON.stringify({ command: "GO_BACK_EMPTY", data: "" })
                  );
                } else {
                  if (!this.coolback) {
                    window.history.go(-1);
                    this.coolback = true;
                    setTimeout(() => {
                      this.coolback = false;
                    }, 500);
                  } else {
                    this.coolback = false;
                    ReactNativeWebView.postMessage(
                      JSON.stringify({ command: "GO_BACK_EMPTY", data: "" })
                    );
                  }
                }
                break;

              case "SET_DEVICE_INFO":
                // Redirect if not actual device
                if (!message.data.isDevice)
                  window.location = "https://stepaheadhealth.com";

                // Store device details
                this.$store.dispatch("setDevice", {
                  isDevice: message.data.isDevice,
                  brand: message.data.brand,
                  deviceName: message.data.deviceName,
                  os: message.data.os,
                  osVersion: message.data.osVersion,
                  manufacturer: message.data.manufacturer,
                  deviceToken: message.data.deviceToken,
                  expoToken: message.data.expoToken,
                });

                break;

              case "DEVICE_INFO":
                // Redirect if not actual device
                if (!message.data.isDevice)
                  window.location = "https://stepaheadhealth.com";

                // Store device details
                this.$store.dispatch("setDevice", {
                  isDevice: message.data.isDevice,
                  brand: message.data.brand,
                  deviceName: message.data.deviceName,
                  os: message.data.os,
                  osVersion: message.data.osVersion,
                  manufacturer: message.data.manufacturer,
                  deviceToken: message.data.deviceToken,
                  expoToken: message.data.expoToken,
                });

                // Validate device token
                try {
                  if (message.data.authToken) {
                    try {
                      // Validate session
                      this.$store.dispatch(
                        "setAuthorization",
                        message.data.authToken
                      );

                      await this.$store.dispatch(
                        "validateSession",
                        message.data.authToken
                      );

                      const decodeToken = this.parseJwt(message.data.authToken);

                      if (decodeToken) {
                        const user = await this.$store.dispatch(
                          "getUserDetails",
                          decodeToken.sub
                        );

                        // Update login device
                        this.$store.dispatch("validateLoginDevice", {
                          userId: user.id,
                          isDevice: message.data.isDevice,
                          brand: message.data.brand,
                          deviceName: message.data.deviceName,
                          os: message.data.os,
                          osVersion: message.data.osVersion,
                          manufacturer: message.data.manufacturer,
                          deviceToken: message.data.deviceToken,
                          expoToken: message.data.expoToken,
                        });

                        switch (user.role) {
                          case "patient":
                            if (!user.is_registration_complete) {
                              this.$router.push(
                                `/patient/step-${user.current_registration_step}`
                              );
                              // setTimeout(() => {
                              //   this.$store.dispatch("showLoader", false);
                              // }, 1000);
                              return;
                            }

                            this.$router.push("/patient/index");
                            // setTimeout(() => {
                            //   this.$store.dispatch("showLoader", false);
                            // }, 1000);
                            break;

                          case "provider":
                            if (!user.is_registration_complete) {
                              this.$router.push(
                                `/provider/step-${user.current_registration_step}`
                              );
                              // setTimeout(() => {
                              //   this.$store.dispatch("showLoader", false);
                              // }, 1000);
                              return;
                            }

                            this.$router.push("/provider/index");
                            // setTimeout(() => {
                            //   this.$store.dispatch("showLoader", false);
                            // }, 1000);
                            break;

                          default:
                            this.$store.dispatch("showLoader", false);
                            Swal.fire(
                              "Ooops!",
                              "You are not authorized to login",
                              "error"
                            );

                            try {
                              await this.$store.dispatch("logOut", {
                                id: this.$store.getters.user.id,
                                deviceToken: this.$store.getters.device
                                  ? this.$store.getters.device.deviceToken
                                  : "",
                              });

                              this.$router.push("/login");
                            } catch (e) {
                              await this.$store.dispatch("logOut", {
                                id: this.$store.getters.user.id,
                                deviceToken: this.$store.getters.device
                                  ? this.$store.getters.device.deviceToken
                                  : "",
                              });
                              this.$router.push("/login");
                            }

                            ReactNativeWebView.postMessage(
                              JSON.stringify({ command: "FLUSH_TOKEN" })
                            );
                        }
                      }
                    } catch (e) {
                      Swal.fire({
                        icon: "error",
                        title: `Ooops!`,
                        text: e,
                        showConfirmButton: false,
                        timer: 2000,
                      });
                      this.$store.dispatch("showLoader", false);
                      this.logout();
                      this.$router.push("/login");
                      ReactNativeWebView.postMessage(
                        JSON.stringify({ command: "FLUSH_TOKEN" })
                      );
                    }
                  } else {
                    this.$store.dispatch("showLoader", false);
                    this.logout();
                    this.$router.push("/login");
                  }
                } catch (e) {
                  Swal.fire({
                    icon: "error",
                    title: `Ooops!`,
                    text: e,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  this.$store.dispatch("showLoader", false);
                  this.$router.push("/login");
                }

                break;
            }
          };
        } else {
          window.location = "https://stepaheadhealth.com";
        }
      }, 500);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: `Ooops!`,
        text: e,
        showConfirmButton: false,
        timer: 2000,
      });
      this.$store.dispatch("showLoader", false);
      this.logout();
      this.$router.push("/login");
    }
  },
  methods: {
    async logout () {
      if (this.$store.getters.user) {
        this.$store.dispatch("logOut", {
          id: this.$store.getters.user.id,
          deviceToken: this.$store.getters.device
            ? this.$store.getters.device.deviceToken
            : "",
        });
      }
    },
    createNewRequest (state) {
      if (state) {
        $("#create-request-modal").modal("show");
      } else {
        $("#create-request-modal").modal("hide");
      }
    },
  },
};
</script>
<style scoped>
.btn-card {
  -webkit-border-radius: 26px;
  border: none;
  border-radius: 26px;
  background: #f8f9fa;
  -webkit-box-shadow: 6px 6px 18px #e9eaeb, -6px -6px 18px #ffffff;
  box-shadow: 6px 6px 18px #e9eaeb, -6px -6px 18px #ffffff;
}

.btn-card:hover {
  background: #fff;
  animation: press 0.1s 1 linear;
}

@keyframes press {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.92);
  }

  to {
    transform: scale(1);
  }
}

@keyframes bounce {
  50% {
    transform: rotate(5deg) translate(20px, -50px);
  }

  to {
    transform: scale(0.9) rotate(10deg) translate(50px, -80px);
    opacity: 0;
  }
}
</style>
