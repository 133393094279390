<template>
  <div class="account-page main-wrapper-adjust-profile">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <h2 class="my-4">Medical License States</h2>
                    </div>
                    <div class="step-list mb-3">
                      <ul>
                        <li>
                          <a href="/app/provider/step-1" class="active-done">1</a>
                        </li>
                        <li>
                          <a href="/app/provider/step-2" class="active-done">2</a>
                        </li>
                        <li>
                          <a href="/app/provider/step-3" class="active-done">3</a>
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">4</a>
                        </li>
                        <li><a href="javascript:void();">5</a></li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                        <li><a href="javascript:void();">9</a></li>
                      </ul>
                    </div>

                    <form @submit.prevent="saveStep">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-4">
                            <div class="form-group text-left">
                              <label>Primary State</label>
                              <input type="text" name="address2" class="form-control" v-model="primaryState" disabled />
                            </div>
                          </div>
                          <div class="col-8">
                            <div class="form-group text-left">
                              <label>Primary State License Number</label>
                              <input type="text" name="state-license-number" class="form-control"
                                placeholder="Max 35 Chars" max="35" v-model="stateLicenseNumber" required />
                            </div>
                          </div>
                        </div>
                        <div class="row" v-for="(st, i) in additionalStates" :key="i">
                          <div class="col-4">
                            <div class="form-group text-left">
                              <label>State {{ i + 1 }}</label>
                              <input type="text" name="address2" class="form-control" :value="st.state_code" disabled />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group text-left">
                              <label>State License Number {{ i + 1 }}</label>
                              <input type="text" name="state-license-number" class="form-control"
                                placeholder="Max 35 Chars" v-model="st.state_license_number" required />
                            </div>
                          </div>
                          <div class="col-2">
                            <img src="../../assets/img/icons/close.png" alt="close" width="26" class="delete-btn"
                              @click="removeState(i)" />
                          </div>
                        </div>

                        <form @submit.prevent="addNewState">
                          <div class="row" v-show="showNewStateForm">
                            <div class="col-7">
                              <div class="form-group">
                                <select class="form-control select" tabindex="-1" aria-hidden="true" v-model="newState"
                                  required>
                                  <option v-for="(state, i) in statesData" :key="i" :value="state.iso2">
                                    {{ state.name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-5">
                              <button :disabled="newState === ''" type="submit" data-dismiss="modal"
                                class="btn btn-primary btn-block">
                                Add State
                              </button>
                            </div>
                          </div>
                        </form>
                        <div class="text-center">
                          <a href="javascript:void();" class="btn btn-info btn-sm btn-block" v-show="!showNewStateForm"
                            @click="showNewStateForm = true">Add Additional License State</a>
                        </div>
                      </div>
                      <div class="mt-3">
                        <hr />
                        <div class="row">
                          <div class="col-12">
                            <button v-show="!showNewStateForm" type="submit" class="
                                btn btn-primary btn-block btn-lg
                                login-btn
                                step1_submit
                                mb-2
                              ">
                              Continue <i class="fa fa-arrow-right ml-2"></i>
                            </button>
                          </div>
                          <div class="col-12">
                            <router-link to="/provider/step-3">
                              <a href="javascript:void()" class="btn btn-light btn-block">
                                <i class="fa fa-arrow-left mr-2"></i> Back
                              </a>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span>© 2023. StepAhead Health Inc. </span>
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import common from "../../mixins/common";
import Swal from "sweetalert2";
export default {
  mixins: [common],
  data () {
    return {
      primaryState: "",
      stateLicenseNumber: "",
      newState: "",
      statesData: [],
      additionalStates: [],
      showNewStateForm: false,
    };
  },
  async mounted () {
    this.showLoader(true);
    this.statesData = await this.getStateList();

    this.primaryState = this.$store.getters.user.state;

    for (let state of this.$store.getters.user.additional_states) {
      this.statesData.splice(
        this.statesData.map((i) => i.iso2).indexOf(state.state_code),
        1
      );

      if (state.type === "primary")
        this.stateLicenseNumber = state.state_license_number;
    }

    this.additionalStates = this.$store.getters.user.additional_states.filter(
      (a) => a.type !== "primary"
    );

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "showLoader"]),

    addNewState () {
      this.additionalStates.push({
        state_code: this.newState,
        state_license_number: "",
      });
      this.newState = "";
      this.showNewStateForm = false;
    },

    removeState (index) {
      this.additionalStates.splice(index, 1);
    },

    async saveStep () {
      try {
        this.showLoader(true);
        let step = 6;
        // if (
        //   this.$store.getters.user.credential === "FNP" ||
        //   this.$store.getters.user.credential === "NP"||
        //   this.$store.getters.user.credential === "PA"
        // ) {
        //   step = 5;
        // }

        // const deaRegex = /^([a-zA-Z]){2}([0-9]){7}?$/;
        // if (!deaRegex.test(this.stateLicenseNumber)) {
        //   Swal.fire(
        //     "Invalid State License Number",
        //     `${this.stateLicenseNumber} is not a valid DEA number`,
        //     "error"
        //   );
        //   this.showLoader(false);
        //   return;
        // }

        // for (let eachState of this.additionalStates) {
        //   if (!deaRegex.test(eachState.state_license_number)) {
        //     Swal.fire(
        //       "Invalid DEA Number",
        //       `${eachState.state_license_number} is not a valid DEA number`,
        //       "error"
        //     );
        //     this.showLoader(false);
        //     return;
        //   }
        // }

        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          additional_states: [
            ...this.additionalStates,
            {
              state_code: this.primaryState,
              state_license_number: this.stateLicenseNumber,
              type: "primary",
            },
          ],
          current_registration_step: step,
        });

        this.$router.push(`/provider/step-${step}`);
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        console.error(e);
      }
    },
  },
};
</script>
<style scoped>
.delete-btn {
  cursor: pointer;
  margin: 38px 0px 0px -10px;
}

.main-wrapper-adjust-profile {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
