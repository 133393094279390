<template>
  <div class="account-page">
    <div class="main-wrapper">
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <h2>Service Agreement</h2>
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="javascript:void();" :class="step > 1 ? `active-done` : `active`">1</a>
                        </li>
                        <li>
                          <a href="javascript:void();" :class="step > 2 ? `active-done` : `active`">2</a>
                        </li>
                        <li>
                          <a href="javascript:void();" :class="step > 3 ? `active-done` : `active`">3</a>
                        </li>
                        <li>
                          <a href="javascript:void();" :class="step > 4 ? `active-done` : `active`">4</a>
                        </li>
                        <li>
                          <a href="javascript:void();" :class="step > 5 ? `active-done` : `active`">5</a>
                        </li>
                      </ul>
                    </div>

                    <!-- LEGAL AGREEMENT 1 -->
                    <div class="card" v-if="step === 1">
                      <div class="card-header text-center">
                        <h3>Legal Agreement 1</h3>
                      </div>
                      <div class="card-body box text-justify m-2">
                        <p>{{ agreementObj.AgreementText }}</p>
                      </div>
                      <div class="card-footer text-center">
                        <button @click="acceptAgreement()" class="btn btn-primary">
                          Accept Legal Agreement 1<i class="fas fa-check ml-2" />
                        </button>
                      </div>
                    </div>

                    <!-- LEGAL AGREEMENT 2 -->
                    <div class="card" v-else-if="step === 2">
                      <div class="card-header text-center">
                        <h3>Legal Agreement 2</h3>
                      </div>
                      <div class="card-body box text-justify m-2">
                        <p>{{ agreementObj.AgreementText }}</p>
                      </div>
                      <div class="card-footer text-center">
                        <button @click="acceptAgreement()" class="btn btn-primary">
                          Accept Legal Agreement <i class="fas fa-check ml-2" />
                        </button>
                      </div>
                    </div>

                    <!-- DISCLAIMER -->
                    <div class="card" v-else-if="step === 3">
                      <div class="card-header text-center">
                        <h3>Disclaimer</h3>
                      </div>
                      <div class="card-body box text-justify m-2">
                        <p>{{ disclaimerObj.disclaimer }}</p>
                      </div>
                      <div class="card-footer text-center">
                        <button @click="acceptIdpDisclaimer()" class="btn btn-primary">
                          Accept Disclaimer <i class="fas fa-check ml-2" />
                        </button>
                      </div>
                    </div>

                    <!-- Add IDP  -->
                    <div class="card" v-else-if="step === 4">
                      <form @submit.prevent="saveIdpDetails">
                        <div class="card-header text-center">
                          <h3>Add Identity Proofing Details</h3>
                        </div>
                        <div class="card-body box text-justify m-2">
                          <div class="row">
                            <div class="col-6">
                              <div class="form-group text-left">
                                <label>First Name</label>
                                <input type="text" name="firstname" v-model="idpDetails.firstName"
                                  class="form-control form-control-xs" disabled required />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group text-left">
                                <label>Last Name</label>
                                <input type="text" name="lastname" v-model="idpDetails.lastName"
                                  class="form-control form-control-xs" disabled required />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form-group text-left">
                                <label>Mobile Number</label>
                                <input type="text" name="mobile" v-model="idpDetails.mobileNumber"
                                  class="form-control form-control-xs" disabled required />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group text-left">
                                <label>City</label>
                                <input type="text" name="city" v-model="idpDetails.city"
                                  class="form-control form-control-xs" disabled required />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form-group text-left">
                                <label>State</label>
                                <input type="text" name="state" v-model="idpDetails.state"
                                  class="form-control form-control-xs" disabled required />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group text-left">
                                <label>Zip Code</label>
                                <input type="text" name="zipcode" v-model="idpDetails.zipCode"
                                  class="form-control form-control-xs" disabled required />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              <div class="form-group text-left">
                                <label>Social Security Number (SSN)</label>
                                <input type="password" name="ssn" v-model="idpDetails.ssn" pattern="\d{9}"
                                  class="form-control form-control-xs" required />
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="form-group text-left">
                                <label>Credit Card Number (16 Digits)</label>
                                <input type="password" name="ccn" v-model="idpDetails.ccn" pattern="^[0-9]{16}$"
                                  class="form-control form-control-xs" required />
                              </div>
                            </div>
                          </div>
                          <div class="text-center">
                            <small class="text-muted">
                              Note: The Social Security Number and Credit Card
                              are for verification purpose. We do not save any
                              of the information.
                            </small>
                          </div>
                        </div>
                        <div class="card-footer text-center">
                          <button type="submit" class="btn btn-primary">
                            Verify IDP Details <i class="fas fa-check ml-2" />
                          </button>
                        </div>
                      </form>
                    </div>

                    <!-- Question and Answers  -->
                    <div class="card" v-else-if="step === 5">
                      <form @submit.prevent="saveQuestionairre">
                        <div class="card-header text-center">
                          <h3>Security Question & Answers</h3>
                        </div>
                        <div class="card-body box text-justify m-2">
                          <div class="mb-5" v-for="(each, i) in questionairre" :key="i">
                            <label><strong>Question {{ i + 1 }}:</strong>
                              {{ each.question }}</label>
                            <label><strong>Answer:</strong></label>
                            <select @change="setAnswer(i, $event)" class="form-control form-control-xs select" required>
                              <option selected>Select the answer</option>
                              <option v-for="(ans, j) in each.answers" :key="j">
                                {{ ans }}
                              </option>
                            </select>
                          </div>
                          <hr />
                          <div class="text-center">
                            <button type="submit" class="btn btn-primary">
                              Save & Proceed <i class="fas fa-check ml-2" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span>© 2023. StepAhead Health Inc. </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { BASE_URL, HTTP_API } from "../../constants/config";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data () {
    return {
      step: 1,
      agreementObj: {},
      disclaimerObj: {},
      idpDetails: {
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        dob: "",
        ssn: "",
        ccn: "",
        phoneNumber: "",
      },
      questionairre: [],
      answers: [],
      sessionId: "",
      sampleQuestions: [],
      sampleAnswers: [],
      agreements: [],
    };
  },
  async mounted () {
    await this.getLegalAgreements();
    this.idpDetails.firstName = this.$store.getters.user.first_name;
    this.idpDetails.lastName = this.$store.getters.user.last_name;
    this.idpDetails.mobileNumber = this.$store.getters.user.primary_phone;
    this.idpDetails.city = this.$store.getters.user.city;
    this.idpDetails.state = this.$store.getters.user.state;
    this.idpDetails.zipCode = this.$store.getters.user.zip_code;
  },
  methods: {
    ...mapActions(["showLoader", "logOut"]),
    async getLegalAgreements () {
      try {
        this.showLoader(true);
        const res = await axios.get(
          `${BASE_URL + HTTP_API.providerGetAgreements}${this.$store.getters.user.id
          }/${this.$store.getters.user.dsp_user_id}`
        );

        if (res.data.status) {
          this.$router.push("/provider/index");
          return;
        }

        this.step = res.data.step;
        if (!this.step) this.step = 1;

        // Set agreements
        if (res.data.agreements[res.data.step - 1]) {
          this.agreementObj = res.data.agreements[res.data.step - 1];
        }

        // Get Disclaimer
        if (this.step === 3) {
          await this.getIdpDisclaimer();
        }

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire({
          title: "Ooops!",
          text: e.response.data.message,
          icon: "warning",
          confirmButtonText: "Log out",
        }).then(async () => {
          await this.logout();
        });
      }
    },

    async acceptAgreement () {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${BASE_URL + HTTP_API.providerAcceptAgreement}${this.$store.getters.user.id
          }/${this.$store.getters.user.dsp_user_id}/${this.agreementObj.AgreementId
          }/${this.step}`
        );

        this.step = res.data.step;

        // Get Disclaimer
        if (this.step === 3) {
          await this.getIdpDisclaimer();
        }

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
      }
    },

    async getIdpDisclaimer () {
      try {
        const res = await axios.get(
          `${BASE_URL + HTTP_API.providerGetDisclaimer}${this.$store.getters.user.id
          }/${this.$store.getters.user.dsp_user_id}`
        );

        this.disclaimerObj = res.data;
      } catch (e) {
        this.showLoader(false);
      }
    },

    async acceptIdpDisclaimer () {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${BASE_URL + HTTP_API.providerAcceptDisclaimer}${this.$store.getters.user.id
          }/${this.$store.getters.user.dsp_user_id}/${this.disclaimerObj.disclaimerId
          }`
        );

        this.step = 4;

        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
      }
    },

    async saveIdpDetails () {
      // if (!this.validateCardNumber(this.idpDetails.ccn)) {
      //   Swal.fire(
      //     "Invalid Credit Card",
      //     "The credit card number is invalid",
      //     "error"
      //   );
      //   return;
      // }

      try {
        this.showLoader(true);
        const res = await axios.post(
          `${BASE_URL + HTTP_API.providerAddIdpDetails}${this.$store.getters.user.id
          }/${this.$store.getters.user.dsp_user_id}`,
          this.idpDetails
        );

        this.step = 5;

        // Set Questions answers for next step
        this.sessionId = res.data.sessionId;
        this.sampleQuestions = res.data.questions;
        this.sampleAnswers = res.data.answers;
        this.questionairre = [];
        for (let each in this.sampleQuestions) {
          this.questionairre.push({
            question: this.sampleQuestions[each],
            answers: this.sampleAnswers[each],
          });
        }
        this.showLoader(false);
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: `Ooops!`,
          text: `${e.response ? e.response.data.message : e}`,
          showConfirmButton: false,
          timer: 2000,
        });
        this.showLoader(false);
      }
    },

    async saveQuestionairre () {
      try {
        if (this.answers.length !== this.sampleQuestions.length) {
          Swal.fire(
            "Answers Missing!",
            "Please answer all the questions.",
            "error"
          );
          return;
        }

        this.showLoader(true);
        const res = await axios.post(
          `${BASE_URL + HTTP_API.providerAddIdpAnswers}${this.$store.getters.user.id
          }/${this.$store.getters.user.dsp_user_id}`,
          { sessionId: this.sessionId, answers: this.answers }
        );

        // console.log(res.data);
        this.showLoader(false);

        Swal.fire(
          "Welcome to StepAhead Health",
          "Thank you for choosing us! :)",
          "success"
        ).then(() => {
          this.$router.push("/provider/index");
        });
      } catch (e) {
        this.showLoader(false);
      }
    },

    setAnswer (questionIndex, event) {
      const question = this.sampleQuestions[questionIndex];
      const answer = event.target.value;

      // Delete if exists
      const exists = this.answers.find((q) => q.question === question);
      if (exists)
        this.answers.splice(
          this.answers.findIndex((q) => q.question === question),
          1
        );

      // Insert the answer
      this.answers.push({
        questionIndex: questionIndex,
        question: question,
        answer: answer,
      });
    },

    async logout () {
      try {
        await this.logOut({
          id: this.$store.getters.user.id,
        });

        this.$router.push("/login");
        // this.$router.go();
      } catch (e) {
        console.error(e);
      }
    },

    validateCardNumber (number) {
      var regex = new RegExp("^[0-9]{16}$");
      if (!regex.test(number)) return false;

      return this.luhnCheck(number);
    },

    luhnCheck (val) {
      var sum = 0;
      for (var i = 0; i < val.length; i++) {
        var intVal = parseInt(val.substr(i, 1));
        if (i % 2 == 0) {
          intVal *= 2;
          if (intVal > 9) {
            intVal = 1 + (intVal % 10);
          }
        }
        sum += intVal;
      }
      return sum % 10 == 0;
    },
  },
};
</script>
<style scoped>
.box {
  max-height: 400px;
  overflow: auto;
}

.logout-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}

.form-control-xs {
  min-height: calc(1em + 0.375rem + 2px) !important;
  padding: 0.125rem 0.5rem !important;
  font-size: 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-xs::placeholder {
  font-size: 0.75rem !important;
}
</style>
