<template>
  <div class="main-wrapper list-holder">
    <div class="content">
      <div class="container-fluid">
        <div v-if="prescription">
          <div class="card mb-2">
            <div class="card-body p-2">
              <div class="row">
                <div class="col-7">
                  <h4>Request ID: {{ prescription.id }}</h4>
                  <span style="font-size: 10px"
                    >Request Date:
                    {{ new Date(prescription.created_at).toDateString() }}</span
                  >
                  <p class="mb-0" style="font-size: 10px">
                    Last Update:
                    {{ new Date(prescription.created_at).toLocaleString() }}
                  </p>
                </div>
                <div class="col-5">
                  <p
                    style="font-size: 13px"
                    :class="getStatusColor(prescription.status)"
                  >
                    <span
                      v-if="
                        prescription.status === 'Accepted' &&
                          prescription.is_payment_completed
                      "
                    >
                      <strong style="font-size: 10px">Approval Pending</strong>
                    </span>
                    <span :class="getStatusColor(prescription.status)" v-else>
                      {{ pharmacyStatus(prescription.status) }}
                    </span>
                  </p>
                  <p class="mt-1" v-if="prescription.provider">
                    <button
                      class="btn btn-sm bg-primary-light btn-block"
                      @click="markChatRead"
                      v-if="!isChatOpen"
                    >
                      <i class="fa fa-comment"></i> Chat
                      <span
                        v-if="unreadMessages > 0"
                        class="badge badge-pill bg-danger-light"
                      >
                        {{ unreadMessages }}</span
                      >
                    </button>
                    <button
                      v-else
                      class="btn btn-sm bg-danger-light btn-block"
                      @click="closeChat"
                    >
                      <i class="fa fa-close"></i> Close Chat
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- REFILL DETAILS -->
          <div class="row" style="font-size: 12px" v-if="!isChatOpen">
            <div class="col-md-4 col-12" id="second-row">
              <div class="accordion" id="prescription-accordion">
                <!-- Assigned Provider Details -->
                <div class="card mb-0" v-if="prescription.provider">
                  <div class="card-header" id="provider-info-accordion">
                    <h3 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#provider-info-collapse"
                        aria-expanded="false"
                        aria-controls="provider-info-collapse"
                      >
                        <i class="fas fa-id-card mr-1"></i> Provider Information
                      </button>
                    </h3>
                  </div>
                  <div
                    id="provider-info-collapse"
                    class="collapse"
                    aria-labelledby="provider-info-accordion"
                    data-parent="#prescription-accordion"
                  >
                    <div class="card-body">
                      <p>
                        <span class="text-muted">Name:</span>
                        {{ prescription.provider.first_name }}
                        {{ prescription.provider.last_name }}
                      </p>
                      <p>
                        <span class="text-muted">Experience: </span>
                        {{ prescription.provider.description }}
                      </p>
                      <p>
                        <span class="text-muted">About:</span><br />
                        <span style="white-space: pre-wrap;">{{
                          prescription.provider.about_me
                        }}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Pharmacy Details -->
                <div class="card mb-0" v-if="prescription.pharmacy">
                  <div class="card-header" id="pharmacy-info-accordion">
                    <h3 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#pharmacy-info-collapse"
                        aria-expanded="false"
                        aria-controls="pharmacy-info-collapse"
                      >
                        <i class="fas fa-hospital mr-1"></i> Pharmacy Details
                      </button>
                    </h3>
                  </div>
                  <div
                    id="pharmacy-info-collapse"
                    class="collapse"
                    aria-labelledby="pharmacy-info-accordion"
                    data-parent="#prescription-accordion"
                  >
                    <div class="card-body">
                      <p>
                        <span class="text-muted">Store ID:</span>
                        {{ prescription.pharmacy.pharmacy_id }}
                      </p>
                      <p>
                        <span class="text-muted">Store Name: </span
                        >{{ prescription.pharmacy.pharmacy_name }}
                      </p>
                      <p>
                        <span class="text-muted">Phone:</span>
                        {{ prescription.pharmacy.phone }}
                      </p>
                      <p>
                        <span class="text-muted">Address:</span>
                        {{ prescription.pharmacy.address1 }},
                        {{ prescription.pharmacy.address2 }},
                        {{ prescription.pharmacy.city }},
                        {{ prescription.pharmacy.state }} -
                        {{ prescription.pharmacy.zip_code }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Patient Details -->
                <div class="card mb-0">
                  <div class="card-header" id="patient-info-accordion">
                    <h3 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#patient-info-collapse"
                        aria-expanded="false"
                        aria-controls="patient-info-collapse"
                      >
                        <i class="fas fa-user mr-1"></i> Your Information
                      </button>
                    </h3>
                  </div>

                  <div
                    id="patient-info-collapse"
                    class="collapse"
                    aria-labelledby="patient-info-accordion"
                    data-parent="#prescription-accordion"
                  >
                    <div class="card-body">
                      <p>
                        <span class="text-muted">Name: </span
                        >{{ $store.getters.user.first_name }}
                        {{ $store.getters.user.last_name }}
                      </p>
                      <p>
                        <span class="text-muted">Email:</span>
                        {{ $store.getters.user.email }}
                      </p>
                      <p>
                        <span class="text-muted">Mobile:</span>
                        {{ $store.getters.user.primary_phone }}
                      </p>
                      <p v-if="$store.getters.user.address1">
                        <span class="text-muted">Address: </span
                        >{{ $store.getters.user.address1 }},
                        {{ $store.getters.user.address2 }},
                        {{ $store.getters.user.city }},
                        {{ $store.getters.user.state }} -
                        {{ $store.getters.user.zip_code }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Observations and Symptoms -->
                <div class="card mb-0" v-if="prescription.observations">
                  <div class="card-header" id="health-info-accordion">
                    <h3 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#health-info-collapse"
                        aria-expanded="false"
                        aria-controls="health-info-collapse"
                      >
                        <i class="fas fa-heart mr-1"></i> Health Observations
                      </button>
                    </h3>
                  </div>
                  <div
                    id="health-info-collapse"
                    class="collapse"
                    aria-labelledby="health-info-accordion"
                    data-parent="#prescription-accordion"
                  >
                    <div class="card-body">
                      <p>
                        <span class="text-muted">Medical History:</span>
                        {{ prescription.observations.history }}
                      </p>
                      <p>
                        <span class="text-muted">Known Allergies:</span>
                        {{ prescription.observations.allergies }}
                      </p>
                      <p>
                        <span class="text-muted">Symptoms / Reason :</span>
                        {{ prescription.observations.symptoms }}
                      </p>
                    </div>
                  </div>
                </div>

                <!-- Payment Details -->
                <div class="card mb-0" v-if="prescription.payment">
                  <div class="card-header" id="payment-info-accordion">
                    <h3 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#payment-info-collapse"
                        aria-expanded="false"
                        aria-controls="payment-info-collapse"
                      >
                        <i class="fas fa-dollar mr-1"></i> Payment Information
                      </button>
                    </h3>
                  </div>
                  <div
                    id="payment-info-collapse"
                    class="collapse"
                    aria-labelledby="payment-info-accordion"
                    data-parent="#prescription-accordion"
                  >
                    <div class="card-body">
                      <p>
                        <span class="text-muted">Provider Fees:</span>
                        ${{
                          parseFloat(prescription.payment.amount).toFixed(2)
                        }}
                        {{ prescription.payment.currency }}
                      </p>
                      <p>
                        <span class="text-muted">Payment Status: </span
                        >{{ prescription.payment.status }}
                      </p>
                      <p>
                        <span class="text-muted">Transaction ID: </span
                        >{{ prescription.payment.trans_id }}
                      </p>
                      <p>
                        <span class="text-muted">Date / Time: </span
                        >{{
                          new Date(
                            prescription.payment.created_at
                          ).toLocaleString()
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Medicines Prescribed -->
            <div class="col-12 mt-2" id="first-row">
              <div v-if="prescription.medicines">
                <div
                  class="card mb-2"
                  v-for="(medicine, i) in prescription.medicines"
                  :key="i"
                >
                  <div class="card-header">
                    <h5>{{ i + 1 }} . {{ medicine.medicine_name }}</h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-3">
                        <h6>
                          <strong>Qty:</strong> {{ medicine.quantity }}
                          {{ medicine.unit_abbreviation }}
                        </h6>
                      </div>
                      <div class="col-3">
                        <h6>
                          <strong>Refills:</strong> {{ medicine.refills }}
                        </h6>
                      </div>
                      <div class="col-6">
                        <h6>
                          <strong>Supply Days:</strong>
                          {{ medicine.supply_days }}
                        </h6>
                      </div>
                      <div class="col-12">
                        <h6 class="mt-2">
                          <strong>Directions:</strong> {{ medicine.directions }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="m-5 text-center"
                v-else-if="prescription.status === 'Cancelled'"
              >
                <h5 class="mt-3">Prescription has been cancelled</h5>
              </div>
              <div class="m-5 text-center" v-else>
                <!-- <div class="card card-table">
                  <div class="card-body"> -->
                <img
                  src="../../assets/img/waiting.png"
                  alt="waiting"
                  class="w-100"
                  style="border-radius: 50px"
                />
                <h5 class="mt-3">Waiting for provider's response...</h5>
              </div>
              <!-- </div>
              </div> -->
            </div>
          </div>
          <div v-else>
            <chatbox
              class="mb-3"
              :providerImage="prescription.provider.profile_image_url"
              :providerName="
                `${prescription.provider.first_name} ${prescription.provider.last_name}`
              "
              :prescriptionId="prescriptionId"
              :status="prescription.status"
            ></chatbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chatbox from "./Chat.vue";

export default {
  props: {
    prescriptionId: String,
  },
  components: {
    chatbox,
  },
  data() {
    return {
      isChatOpen: false,
      interval: null,
    };
  },
  computed: {
    prescription() {
      return this.$store.getters.prescriptions.find(
        (p) => p.id == this.prescriptionId
      );
    },
    chatMessages() {
      return this.$store.getters.chatMessages;
    },
    unreadMessages() {
      return this.$store.getters.chatMessages
        ? this.$store.getters.chatMessages
            .filter((d) => d.user_id !== this.$store.getters.user.id)
            .filter((c) => c.is_read === false).length
        : 0;
    },
    chatEnabled() {
      return this.prescription.status === "Completed" ||
        this.prescription.status === "Cancelled"
        ? false
        : true;
    },
    route() {
      return this.$route.name;
    },
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  },
  watch: {
    route: function() {
      if (this.route !== "view-prescription") {
        if (this.interval) clearInterval(this.interval);
        this.interval = null;
        this.$socket.emit("REQUEST", {
          command: "GET:CLEAR_CHAT",
          userId: this.$store.getters.user.id,
        });
      }
    },
    chatMessages: function(newVal, oldVal) {
      if (newVal.length != oldVal.length && this.isChatOpen) {
        this.$socket.emit("REQUEST", {
          command: "POST:CHAT_MESSAGES_MARK_READ",
          userId: this.$store.getters.user.id,
          prescriptionId: this.prescriptionId,
        });
      }
    },
  },
  mounted() {
    // CHAT MESSAGE REQUESTS
    this.$socket.emit("REQUEST", {
      command: "GET:CHAT_MESSAGES",
      userId: this.$store.getters.user.id,
      prescriptionId: this.prescriptionId,
    });

    if (this.chatEnabled) {
      this.interval = setInterval(() => {
        if (!this.chatEnabled) {
          if (this.interval) clearInterval(this.interval);
          this.interval = null;
          return;
        }

        this.$socket.emit("REQUEST", {
          command: "GET:CHAT_MESSAGES",
          userId: this.$store.getters.user.id,
          prescriptionId: this.prescriptionId,
        });
      }, 2000);
    } else {
      if (this.interval) clearInterval(this.interval);
      this.interval = null;
    }
  },
  methods: {
    closeChat() {
      this.isChatOpen = false;
    },
    markChatRead() {
      this.$socket.emit("REQUEST", {
        command: "POST:CHAT_MESSAGES_MARK_READ",
        userId: this.$store.getters.user.id,
        prescriptionId: this.prescriptionId,
      });

      this.isChatOpen = true;
    },
    pharmacyStatus(status) {
      switch (status) {
        case "Sent":
          return "Sent To Pharmacy";

        case "PharmacyVerified":
          return "Pharmacy Verified";
      }

      return status;
    },
    getStatusColor(status) {
      switch (status) {
        case "Awaiting":
          return "btn btn-sm bg-warning-light btn-block p-0 m-0";

        case "In Progress":
          return "btn btn-sm bg-warning-light btn-block p-0 m-0";

        case "Completed":
          return "btn btn-sm bg-success-light btn-block p-0 m-0";

        case "Sent":
        case "PharmacyVerified":
        case "Accepted":
          return "btn btn-sm bg-success-light btn-block p-0 m-0";

        case "Cancelled":
          return "btn btn-sm bg-danger-light btn-block p-0 m-0";

        default:
          return "btn btn-sm bg-primary-light btn-block p-0 m-0";
      }
    },
  },
};
</script>

<style scoped>
#first-row {
  order: 1;
}

#second-row {
  order: 1;
}

.list-holder {
  height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
